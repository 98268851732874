import React, { FC } from "react";

const MainNavbarContainer: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className=" flex flex-col w-full desktop:w-[calc(100vw-272px)]">
    {children}
  </div>
);

export default MainNavbarContainer;
