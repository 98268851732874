import React, { useContext, useEffect, useState } from "react";
import { Main } from "@/components/common/main";
import UserContext from "@/context/user";
import { Label } from "@/components/common/label";
import useTranslation from "next-translate/useTranslation";
import KumuButtonComponent from "@/components/layout/kumu/button-component/ButtonComponent";
import MainLayoutWrapper from "@/features/kumu/common/main-layout-wrapper";
import MainNavbarContainer from "@/features/kumu/common/main-navbar-container";
import MainNavbar from "@/features/kumu/common/main-navbar";
import KumuMainLayoutComponent from "@/components/layout/kumu/main-layout-component/MainLayoutComponent";
import { Link } from "@/components/common/link";
import { KUMU_URLS } from "@/utils/urls/kumu";

export default function Custom404() {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [mode, setMode] = useState<string>();
  useEffect(() => {
    let mode = localStorage.getItem("mode");
    setMode(mode ? mode : "");
  }, []);
  return (
    <>
      <>
        <KumuMainLayoutComponent>
          <MainLayoutWrapper />
          <MainNavbarContainer>
            <MainNavbar />

            <Main className="min-w-full h-full">
              <section className=" flex flex-col items-center justify-center pt-[150px] gap-4">
                <Label as="h1" fontSize="2xl" fontWeight="semibold">
                  {t("common:404.message")}
                </Label>
                <Label as="h3" fontSize="md" fontWeight="light">
                  {t("common:404.pageNotFound")}
                </Label>

                <div className={`relative  max-w-[820px]`}>
                  <Link
                    href={
                      mode == "expert"
                        ? KUMU_URLS.PAGES.B2B.MAIN
                        : KUMU_URLS.PAGES.B2C.MAIN
                    }
                  >
                    <KumuButtonComponent theme={"black"} className="mt-3">
                      Back to home
                    </KumuButtonComponent>
                  </Link>
                </div>
              </section>
            </Main>
          </MainNavbarContainer>
        </KumuMainLayoutComponent>
      </>
    </>
  );
}
