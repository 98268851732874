import React, { FC } from "react";

interface OwnProps {
  className?: string;
  children: React.ReactNode;
}

const KumuMainLayoutComponent: FC<OwnProps> = (props: OwnProps) => (
  <section className="flex">{props.children}</section>
);

export default KumuMainLayoutComponent;
